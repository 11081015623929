import React from 'react';
import './popup.css';
import Babs from '../images/profiles/babs-fs8.png';
import Jpegjoey from '../images/profiles/jpegjoey-fs8.png';
import Moto from '../images/profiles/moto-fs8.png';
import Paypie from '../images/profiles/paypie-fs8.png';
import Qbeast from '../images/profiles/qbeast-fs8.png';
import Reilly from '../images/profiles/reilly-fs8.png';
import Steeby from '../images/profiles/steeby-fs8.png';
import Zowo from '../images/profiles/zowo-fs8.png';
import Arie from '../images/profiles/arie-fs8.png'
import Amaron from '../images/profiles/amaron-fs8.png'
import Danytha from '../images/profiles/danytha-fs8.png'
import Soulitude from '../images/profiles/soulitude-fs8.png'
import Insta from '../images/instagram.png';

const idToImageData = {
	babs: Babs,
	jpegjoey: Jpegjoey,
	moto: Moto,
	paypie: Paypie,
	qbeast: Qbeast,
	obey: Reilly,
	steeby: Steeby,
	zowo: Zowo,
	arie: Arie,
	amaron: Amaron,
	danytha: Danytha,
	soulitude: Soulitude,
};

const Popup = (profileData, profileId) => {
	return (
		<>
			<div class="popup-container">
				<div class="popup-content border-top-bottom">
					<div class="popup-left">
						<h2>{profileData.name}</h2>
						<p class="location">{profileData.location}</p>
						{profileData.paragraphs.map((element) => {
							return <p>{element}</p>;
						})}
						<p>
							<em>{profileData.quote}</em>
						</p>
					</div>
					<div class="popup-right">
						<img src={idToImageData[profileId]} />
						<span class="link-container">
							<a href={profileData.ig.link}>
								<img src={Insta} />
								<span class="text">{profileData.ig.handle}</span>
							</a>
						</span>
					</div>
					<div
						class="close-button"
						onClick={() => (document.getElementById('popup').style.display = 'none')}
					>
						X
					</div>
				</div>
			</div>
		</>
	);
};

export default Popup;
