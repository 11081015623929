import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export const Moto = () => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "moto.png" }) {
				childImageSharp {
					fluid(maxWidth: 1000) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	if (!data?.placeholderImage?.childImageSharp?.fluid) {
		return <div>Picture not found</div>;
	}

	return <Img fluid={data.placeholderImage.childImageSharp.fluid} />;
};
