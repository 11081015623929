/**
 * Image component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import Popup from './popup';
import ProfileData from './data.json';

const Image = ({ children }) => {
	return (
		<>
			<div class="image" onClick={showPopup(children.props.profileId)}>
				<div class="image-overlay">
					<div class="image-name">{getProfileData(children.props.profileId).name}</div>
					<div class="image-overlay-background"></div>
				</div>
				<div class="image-content">{children}</div>
			</div>
		</>
	);
};

const showPopup = (profileId) => {
	return () => {
		const popupContainer = document.getElementById('popup');
		popupContainer.style.display = 'block';
		const profileData = getProfileData(profileId);
		ReactDOM.render(Popup(profileData, profileId), popupContainer);
	};
};

const getProfileData = (profileId) => {
	return ProfileData[profileId];
};

export default Image;
