import React from 'react';

const TextContent = () => (
	<>
		<p>
			Wurdspøken is een collaborate collective met <u>Zowo</u>, <u>Paypie</u>,
			<u>Amaron</u>, <u>Babs</u>, <u>Jpeg Joey</u>, <u>Danytha</u>, <u>Arie</u>,
			<u>Soulitude</u>, <u>Alexander Moto</u>, <u>Obey Riley</u>, <u>Qbeast en
				Steeby</u> </p>
		<p>
			We zijn een collectief van producers, rappers en vocalisten uit de
			noord-en-oostelijke provincies van Nederland. Gezamenlijk maakten we een
			mixtape die in het teken stond van onze achtergronden en invloeden, onze
			eigen taal en ons gevoel bij de Noord-en Oostelijke omgevingen. Deze tape
			releasen we in 2022 digitaal en op cassette, met bijpassende muziekvideo's.
			Wurdspøken komt vanaf herfst met een spectaculaire liveshow naar de
			Nederlandse festivals en podia.
		</p>
		<p>
			Onze live show is een back to back rollercoaster aan karakters en eigentijdse
			stijlen. Verwacht: Harde 808’s, krachtige personages, kwetsbare momenten in
			een hiphop show die je bij de keel grijpt. We brengen allemaal weer een
			eigen palet aan smaken en skills en deze eclectische mix is precies wat
			Wurdspøken interessant maakt. Het is daarnaast voor het eerst dat wij als
			artiesten uit Gelderland, Overijssel, Drenthe, Friesland en Groningen
			gezamenlijk aan een liveshow werken. Binnen Wurdspøken heeft iedereen een
			eigen connectie met de noordelijke en oosterse streken. Die connectie levert
			een een dynamische mix aan ervaringen op die door meertaligheid tot leven
			komen in spannende songs. Wurdspøken brengt in april 2022 een mixtape uit
			die digitaal en op cassette gereleased wordt.
		</p>
	</>
);

export default TextContent;
