import React from 'react';
import './twocolumnlayout.css';
import TextContent from './text';
import ImageContainer from './imagecontainer.js';

const TwoColumnLayout = () => (
	<div class="flex-container">
		<div id="popup" style={{ display: `none` }}></div>
		<div class="flex-left border-top-bottom">
			<TextContent
				style={{
					position: `relative`,
					top: `50%`,
					'-webkit-transform': `translateY(-50%)`,
					'-ms-transform': `translateY(-50%)`,
					transform: `translateY(-50%)`,
				}}
			/>
		</div>
		<div class="flex-right border-top-bottom">
			<ImageContainer />
		</div>
	</div>
);

export default TwoColumnLayout;
