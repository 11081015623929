import { Link } from 'gatsby';
import Icon from '../logo/Wurdspeuken-Logo-Green-web.svg';
import PropTypes from 'prop-types';
import React from 'react';
import Insta from '../images/instagram.png';
import FB from '../images/facebook.png';

const Header = () => (
	<header
		style={{
			background: `black`,
			marginBottom: `1.45rem`,
		}}
	>
		<div
			style={{
				margin: `0 auto`,
				padding: `1.45rem 0 0 1.45rem`,
			}}
		>
			<div style={{ maxWidth: `60vw` }}>
				<Icon />
			</div>
			<div
				style={{
					right: `0`,
				}}
			>
				{/* <img src={Insta} /> */}
				{/* <img src={FB} /> */}
			</div>
		</div>
	</header>
);

export default Header;
