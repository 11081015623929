import React from 'react';

const Footer = () => {
	return (
		<p
			style={{
				textAlign: `center`,
				fontSize: `12px`,
				margin: `auto`,
				paddingTop: `2em`,
			}}
		>
			Wurdspøken is coproductie van De Nieuwe Oost, Cultuur Oost, Stichting Kunst &
			Cultuur, Popfabryk en Noordstaat. Wurdspøken komt mede tot stand door
			ondersteuning van de Proeftuin Meertaligheid van We The North en Fonds21
		</p>);
};

export default Footer;
