import React from 'react';

import Layout from '../components/layout';
import TwoColumnLayout from '../components/twocolumnlayout';
import SEO from '../components/seo';

const IndexPage = () => (
	<Layout>
		<SEO title="Home" />
		<TwoColumnLayout />
	</Layout>
);

export default IndexPage;

// <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
// 	<Image />
// </div>
