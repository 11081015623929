import React from 'react';
import { Ameron, Arie, Babs, Danytha, Jpegjoey, Moto, Paypie, Qbeast, Reily, Soulitude, Steeby, Zowo } from './images/images';
import Image from './image';
import './imagecontainer.css';

const ImageContainer = () => (
	<div class="image-container">
		<Image>
			<Reily profileId="obey" />
		</Image>
		<Image>
			<Ameron profileId="amaron" />
		</Image>
		<Image>
			<Danytha profileId="danytha" />
		</Image>
		<Image>
			<Arie profileId="arie" />
		</Image>
		<Image>
			<Steeby profileId="steeby" />
		</Image>
		<Image>
			<Babs profileId="babs" />
		</Image>
		<Image>
			<Moto profileId="moto" />
		</Image>
		<Image>
			<Qbeast profileId="qbeast" />
		</Image>
		<Image>
			<Jpegjoey profileId="jpegjoey" />
		</Image>
		<Image>
			<Soulitude profileId="soulitude" />
		</Image>
		<Image>
			<Paypie profileId="paypie" />
		</Image>
		<Image>
			<Zowo profileId="zowo" />
		</Image>
	</div>
);

export default ImageContainer;
